import { CircularProgress } from "@mui/material";
import { DataGrid, GridRowClassNameParams, GridRowParams } from "@mui/x-data-grid";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { StringParam, useQueryParam } from "use-query-params";
import { GetHPAPolicies, GetHPAPoliciesResponse } from "../../../api/fetcher";
import { components } from "../../../api/schema";
import useGetUserRole from "../../../MainMenu/useGetUserRole";
import { isBuiltInPolicy } from "../../../utils/policyUtils";
import { getDataGridSx, HEADER_HEIGHT } from "../../../utils/styleUtils";
import { ScaleOpsProduct } from "../../../utils/typesUtils";
import useIsReadyOnlyFrontEnd from "../../../utils/useIsReadyOnlyFrontEnd";
import PolicyPageTitleAndDescription from "../../Policies/PolicyPageTitleAndDescription";
import usePoliciesStats from "../../Policies/usePoliciesStats";
import useDeleteHPAPolicy from "../hooks/useDeleteHPAPolicy";
import { DEFAULT_POLICY_NAME, getColumns, POLICY_ROW_HEIGHT, Row } from "../utils";
import DuplicateHPAPolicy from "./DuplicateHPAPolicy";
import EditHPAPolicy from "./EditHPAPolicy";

const HPAPoliciesQuery = GetHPAPolicies();

const HPAPoliciesTable = () => {
  const { userRole } = useGetUserRole();
  const policiesStats = usePoliciesStats({});
  const isReadyOnlyFrontEnd = useIsReadyOnlyFrontEnd();
  const deleteHPAPolicy = useDeleteHPAPolicy();

  const [rows, setRows] = useState<Row[] | undefined>();
  const [selectedPolicyToDuplicate, setSelectedPolicyToDuplicate] = useState<
    components["schemas"]["V1alpha1HpaPolicy"] | undefined
  >(undefined);
  const [selectedPolicyToEdit, setSelectedPolicyToEdit] = useState<
    components["schemas"]["V1alpha1HpaPolicy"] | undefined
  >(undefined);

  const [policyToOpenOnInitialLoading, setPolicyToOpenOnInitialLoading] = useQueryParam(
    "policyToOpenOnInitialLoading",
    StringParam
  );

  const { data, isLoading, error } = useQuery<GetHPAPoliciesResponse, Error>({
    queryKey: [HPAPoliciesQuery.queryKey],
    queryFn: () => HPAPoliciesQuery.queryFn(),
  });

  const handleOpenPolicyDialogue = (params: Row["rowPolicyData"]) => {
    console.log(params);
  };

  useEffect(() => {
    let rowsData: Row[] | undefined = data?.policies?.map((policy, index) => {
      return {
        id: index,
        name: String(policy.metadata?.name),
        rowPolicyData: policy,
        isBuiltIn: isBuiltInPolicy(policy),
      };
    });

    // show default policies first
    rowsData = rowsData?.sort((a, b) => {
      if (a?.isBuiltIn && !b?.isBuiltIn) return -1;
      if (!a?.isBuiltIn && b?.isBuiltIn) return 1;
      return 0;
    });

    // show production policy first
    rowsData = rowsData?.sort((a, b) => {
      if (a.name === DEFAULT_POLICY_NAME && b.name !== DEFAULT_POLICY_NAME) return -1;
      if (a.name !== DEFAULT_POLICY_NAME && b.name === DEFAULT_POLICY_NAME) return 1;
      return 0;
    });

    setRows(rowsData ?? []);
  }, [data]);

  useEffect(() => {
    if (rows && rows.length > 0 && policyToOpenOnInitialLoading) {
      const policyToOpen = rows.find((row) => row.name === policyToOpenOnInitialLoading);

      if (policyToOpen) {
        handleOpenPolicyDialogue(policyToOpen.rowPolicyData);
      }
      setPolicyToOpenOnInitialLoading(undefined);
    }
  }, [rows, policyToOpenOnInitialLoading]);

  if (error) {
    console.log("error fetching HPA policies table:", error);
    return null;
  }

  if (isLoading) {
    return (
      <div className="w-full h-[50vh] flex justify-center items-center">
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      <div className="flex flex-col gap-8">
        <PolicyPageTitleAndDescription scaleOpsProduct={ScaleOpsProduct.HPA} />
        <DataGrid
          rows={rows ?? []}
          columns={getColumns({
            policiesStats,
            isReadyOnlyFrontEnd,
            userRole,
            deleteHPAPolicy,
            setSelectedPolicyToDuplicate,
            setSelectedPolicyToEdit,
          })}
          sx={getDataGridSx()}
          hideFooter
          autoHeight
          disableSelectionOnClick
          disableColumnMenu
          headerHeight={HEADER_HEIGHT}
          rowHeight={POLICY_ROW_HEIGHT}
          onRowClick={(params: GridRowParams<Row>) => {
            if (params.row.isBuiltIn) return;
            handleOpenPolicyDialogue(params.row.rowPolicyData);
          }}
          getRowClassName={(row: GridRowClassNameParams<Row>) => {
            if (row?.row?.isBuiltIn) return "cursor-pointer automatedRow";

            return "cursor-pointer";
          }}
        />
      </div>
      {selectedPolicyToDuplicate && (
        <DuplicateHPAPolicy
          selectedPolicyToDuplicate={selectedPolicyToDuplicate}
          setSelectedPolicyToDuplicate={setSelectedPolicyToDuplicate}
          allPolicies={data?.policies}
        />
      )}
      {selectedPolicyToEdit && (
        <EditHPAPolicy selectedPolicyToEdit={selectedPolicyToEdit} setSelectedPolicyToEdit={setSelectedPolicyToEdit} />
      )}
    </>
  );
};

export default HPAPoliciesTable;
